import { random } from 'lodash';

import { useModal } from '@tager/web-components';

import useSettingItem from '@/hooks/useSettingItem';
import { googleEvent } from '@/utils/events';
import CarSelectionHelperModal from '@/components/modals/CarSelectionHelperModal';
import { StyledOverlay } from '@/components/modals/shared/StyledOverlay';
import { CarSelectionHelperFormFields } from '@/components/modals/CarSelectionHelperModal/types';
import { submitCarHelper } from '@/services/leads/leads-service';

export function useCarSelectionHelper() {
  const modal = useModal();

  const images = useSettingItem('HELPER_CARD_IMAGES');
  const randomValue = random(0, images ? images.length - 1 : 0);

  const modalText = useSettingItem('HELPER_MODAL_TEXT');
  const modalImage = useSettingItem('HELPER_MODAL_IMAGE');

  const cardGoogleEvent = useSettingItem('HELPER_CARD_GOOGLE_EVENTS')?.[
    randomValue
  ]?.googleEvent;
  const cardModalGoogleEvent = useSettingItem(
    'HELPER_CARD_MODAL_GOOGLE_EVENTS'
  )?.[randomValue]?.googleEvent;

  const image = images?.[randomValue];

  const dieText = useSettingItem('HELPER_DIE_TEXT');
  const dieImageOn = useSettingItem('HELPER_DIE_IMAGE_ON');
  const dieImageOff = useSettingItem('HELPER_DIE_IMAGE_OFF');
  const dieGoogleEvent = useSettingItem('HELPER_DIE_GOOGLE_EVENT');
  const dieModalGoogleEvent = useSettingItem('HELPER_DIE_MODAL_GOOGLE_EVENT');

  async function handleSubmitCarSelectionHelperForm(
    values: CarSelectionHelperFormFields
  ): Promise<void> {
    try {
      await submitCarHelper(values);
    } catch (error: any) {
      return Promise.reject(error);
    }
  }

  const openModal = (isDieModal: boolean) => {
    if (!isDieModal && cardGoogleEvent) {
      googleEvent(cardGoogleEvent);
    } else if (dieGoogleEvent) {
      googleEvent(dieGoogleEvent);
    }

    modal(
      CarSelectionHelperModal,
      {
        text: modalText ?? '',
        image: modalImage ?? { url: '' },
        onSubmit: (values) =>
          handleSubmitCarSelectionHelperForm(values).then(() => {
            if (!isDieModal && cardModalGoogleEvent) {
              googleEvent(cardModalGoogleEvent);
            } else if (dieModalGoogleEvent) {
              googleEvent(dieModalGoogleEvent);
            }
          }),
      },
      { components: { Overlay: StyledOverlay }, scrollLockDisabled: true }
    );
  };

  return {
    image,
    openModal: () => openModal(false),
    die: {
      text: dieText ?? '',
      imageOn: dieImageOn ?? { url: '' },
      imageOff: dieImageOff ?? { url: '' },
      onClick: () => openModal(true),
    },
  };
}
