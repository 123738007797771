import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { FormikErrors, useFormik } from 'formik';

import { convertRequestErrorToMap } from '@tager/web-core';
import { convertThumbnailToPictureProps } from '@tager/web-modules';

import { useLockBodyScroll } from '@/hooks/useLockBodyScroll';
import { colors } from '@/constants/theme';
import Picture from '@/components/Picture';
import StockFilterInput from '@/components/StockFilterInput';
import StockFilterTextArea from '@/components/StockFilterTextArea';
import Checkbox, { CheckboxAgreement } from '@/components/Checkbox';
import Button from '@/components/Button';
import Loader from '@/components/Loader';
import { media } from '@/utils/mixin';
import SuccessForm from '@/components/Forms/SuccessForm';

import { CarSelectionHelperModalProps } from './types';
import { CarSelectionHelperFormFields } from './types';
import {
  initialCarSelectionHelperFormValues,
  validationCarSelectionHelperFormSchema,
} from './helpers';

function CarSelectionHelperModal({
  closeModal,
  innerProps,
}: CarSelectionHelperModalProps) {
  const { text, image, onSubmit } = innerProps;

  const [isFormSend, setFormSend] = useState(false);
  const [userAgreement, setUserAgreement] = useState(false);

  useLockBodyScroll();

  const {
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    setFieldValue,
    setErrors,
    isSubmitting,
  } = useFormik<CarSelectionHelperFormFields>({
    initialValues: initialCarSelectionHelperFormValues,
    validationSchema: validationCarSelectionHelperFormSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);

      try {
        await onSubmit?.(values).then(() => {
          setFormSend(true);
        });
      } catch (error: any) {
        const errorMap = convertRequestErrorToMap(
          error
        ) as FormikErrors<CarSelectionHelperFormFields>;
        setErrors(errorMap);
        console.log(error);
      }

      setSubmitting(false);
    },
  });

  return (
    <Component isFormSend={isFormSend}>
      {isFormSend ? (
        <SuccessForm />
      ) : (
        <Main>
          <Content>
            <Inner>
              <Text dangerouslySetInnerHTML={{ __html: text }} />
            </Inner>

            <Image loading="lazy" {...convertThumbnailToPictureProps(image)} />
          </Content>
          <Form onSubmit={handleSubmit} noValidate autoComplete="off">
            <Field>
              <StockFilterInput
                id="name"
                name="name"
                label="Имя"
                value={values.name}
                onChange={handleChange}
                errorMessage={
                  errors.name && touched.name ? errors.name : undefined
                }
              />
            </Field>

            <Field>
              <StockFilterInput
                id="phone"
                name="phone"
                label="Телефон"
                placeholder="+375 (__) ___-__-__"
                value={values.phone}
                onChange={handleChange}
                errorMessage={
                  errors.phone && touched.phone ? errors.phone : undefined
                }
              />
            </Field>

            <Field>
              <StockFilterTextArea
                id="message"
                name="message"
                label="Комментарий"
                value={values.message}
                onChange={handleChange}
              />
            </Field>

            <Field>
              <Checkboxes>
                <Field>
                  <Checkbox
                    label="Хочу взять в Trade-in"
                    onChange={(value) => setFieldValue('tradeIn', value)}
                  />
                </Field>

                <Field>
                  <Checkbox
                    label="Планирую взять кредит"
                    onChange={(value) => setFieldValue('credit', value)}
                  />
                </Field>
              </Checkboxes>
            </Field>

            <Field>
              <CheckboxAgreement
                checked={userAgreement}
                onChange={() => setUserAgreement(!userAgreement)}
              />
            </Field>

            <Actions>
              <StyledButton variant="outlined" onClick={closeModal}>
                Отмена
              </StyledButton>
              <StyledButton
                variant="contained"
                type="submit"
                disabled={!isSubmitting ? !userAgreement : true}
              >
                {!isSubmitting ? 'Отправить' : <Loader color={colors.white} />}
              </StyledButton>
            </Actions>
          </Form>
        </Main>
      )}
    </Component>
  );
}

export default CarSelectionHelperModal;

const Component = styled.div<{ isFormSend: boolean }>`
  width: calc(100vw - 20px);
  max-width: 915px;

  ${({ isFormSend }) =>
    isFormSend &&
    css`
      padding: 40px;
      border-radius: 10px;
      background: ${(props) => props.theme.white};

      ${media.mobile(css`
        padding: 40px 20px;
      `)}
    `}
`;

const Main = styled.div`
  display: flex;

  ${media.mobile(css`
    flex-direction: column;
  `)}
`;

const Content = styled.div`
  flex: 1 1 50%;
  max-width: 50%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 10px 0 0 10px;
  background: ${colors.blue700};

  ${media.mobile(css`
    flex: auto;
    max-width: none;
    border-radius: 10px 10px 0 0;
  `)}
`;

const Inner = styled.div`
  padding: 40px 40px 0;

  ${media.mobile(css`
    padding: 40px 20px 0;
  `)}
`;

const Text = styled.div`
  color: ${colors.white};

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 700;
    font-size: 30px;
    line-height: 36px;
    margin-bottom: 20px;
  }

  p {
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 10px;
  }

  ul,
  ol {
    padding-left: 16px;

    li {
      font-size: 16px;
      line-height: 19px;
    }
  }

  ul {
    list-style-type: disc;
  }

  ol {
    list-style-type: decimal;
  }
`;

const Image = styled(Picture)`
  width: 100%;
  padding-top: 62.296%;

  ${media.mobile(css`
    padding-top: 61.127%;
  `)}

  picture,
  img {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
  }

  img {
    object-fit: cover;
  }
`;

const Form = styled.form`
  padding: 40px;
  flex: 1 1 50%;
  max-width: 50%;
  border-radius: 0 10px 10px 0;
  background: ${(props) => props.theme.white};

  ${media.mobile(css`
    padding: 40px 20px;
    flex: auto;
    max-width: none;
    border-radius: 0 0 10px 10px;
  `)}
`;

const Field = styled.div`
  &:not(:first-child) {
    margin-top: 20px;
  }

  textarea {
    min-height: 90px;
  }
`;

const Checkboxes = styled.div`
  padding: 30px 15px;
  border-radius: 6px;
  background: ${(props) => props.theme.gray1000_alpha30};
`;

const Actions = styled.div`
  display: flex;
  margin-top: 40px;

  ${media.mobile(css`
    flex-direction: column-reverse;
  `)}
`;

const StyledButton = styled(Button)`
  &:not(:first-child) {
    margin-left: 20px;

    ${media.mobile(css`
      margin: 0 0 15px;
    `)}
  }
`;
