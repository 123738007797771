import React from 'react';
import styled from 'styled-components';

import { ScrollStyles } from '@/components/styles';

import { StockFilterTextAreaProps } from './types';

function StockFilterTextArea({
  className,
  label,
  ...props
}: StockFilterTextAreaProps) {
  return (
    <Component className={className}>
      {label && <Label>{label}</Label>}
      <Area {...props} />
    </Component>
  );
}

export default StockFilterTextArea;

const Component = styled.div`
  position: relative;
  width: 100%;
`;

const Label = styled.p`
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 10px;
  color: ${(props) => props.theme.blue100};
`;

const Area = styled.textarea`
  display: block;
  width: 100%;
  padding: 11px 15px 10px;
  min-height: 120px;
  font-size: 16px;
  line-height: 19px;
  background: ${(props) => props.theme.white_1};
  color: ${(props) => props.theme.black200};
  border: 1px solid ${(props) => props.theme.gray1000};
  border-radius: 6px;
  resize: none;

  ${ScrollStyles}

  &::placeholder {
    color: ${(props) => props.theme.gray1500};
    opacity: 1;
  }
`;
