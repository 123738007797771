import React from 'react';
import styled, { css, keyframes } from 'styled-components';

import { convertThumbnailToPictureProps } from '@tager/web-modules';

import { colors } from '@/constants/theme';
import Picture from '@/components/Picture';

import { CarSelectionHelperDieProps } from './types';

function CarSelectionHelperDie({
  text,
  imageOn,
  imageOff,
  onClick,
  className,
}: CarSelectionHelperDieProps) {
  return (
    <Component className={className} onClick={onClick}>
      <Text>{text}</Text>

      <Image loading="lazy" {...convertThumbnailToPictureProps(imageOff)} />
      <Image
        animated
        loading="lazy"
        {...convertThumbnailToPictureProps(imageOn)}
      />
    </Component>
  );
}

export default CarSelectionHelperDie;

const Component = styled.button`
  position: relative;
  width: 100%;
  height: 60px;
  padding: 12px 20px;
  font-size: 16px;
  line-height: 19px;
  border-radius: 15px 15px 0 0;
  background: ${colors.blue700};
`;

const Text = styled.p`
  z-index: 1;
  position: relative;
  max-width: 200px;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: left;
  color: ${colors.white};
`;

const imageShown = keyframes`
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

const Image = styled(Picture)<{ animated?: boolean }>`
  position: absolute;
  top: 0;
  right: 0;
  width: 206px;
  height: 60px;

  picture,
  img {
    width: 100%;
    height: 100%;
    border-radius: 0 15px 0 0;
  }

  img {
    object-fit: cover;
  }

  ${({ animated }) =>
    animated &&
    css`
      animation: ${imageShown} 2s infinite;
    `}
`;
