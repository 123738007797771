import * as Yup from 'yup';

import { CarSelectionHelperFormFields } from './types';

export const initialCarSelectionHelperFormValues: CarSelectionHelperFormFields =
  {
    name: '',
    phone: '',
    tradeIn: false,
    credit: false,
    message: '',
  };

export const validationCarSelectionHelperFormSchema: Yup.SchemaOf<CarSelectionHelperFormFields> =
  Yup.object().shape({
    name: Yup.string().required('Введите имя'),
    phone: Yup.string().required('Введите телефон'),
    tradeIn: Yup.boolean(),
    credit: Yup.boolean(),
    message: Yup.string(),
  });
